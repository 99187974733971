<template>
  <div class="card my-5" v-if="!token">
    <div class="card-header">
      <h4>Login</h4>
    </div>
    <div class="card-body">
      <form @submit.prevent="login">
        <div class="row gy-3">
          <div class="col-12">
            <label for="username" class="col-form-label">E-Mail-Adresse</label><br>
            <input type="email" autocomplete="username" v-model.trim="email" class="form-control" id="username"
                   placeholder="v.nachname@mcself-mietlager.at">
          </div>

          <div class="col-12">
            <label for="inputPassword3" class="col-form-label">Password</label><br>
            <input type="password" id="password" autocomplete="current-password" v-model.trim="password"
                   class="form-control">
          </div>
          <div class="col-12">
            <button type="submit" class="btn btn-primary">Anmelden</button>
          </div>
          <div class="col-12">
            Verwenden Sie die E-Mail und Passwort, mit denen Sie sich in Storeganise unter <a
              href="https://mein.mcself-mietlager.at/admin"
              target="_blank">mein.mcself-mietlager.at/admin</a>
            anmelden.
          </div>
        </div>
      </form>
    </div>
  </div>
  <form v-else @submit.prevent="logout">
    <button type="submit" class="btn btn-outline-primary btn-sm">Abmelden</button>
  </form>
</template>

<script setup>
import {computed, ref} from "vue";
import store from "@/store";

const email = ref();
const password = ref();

const token = computed(() => store.getters.token);

const login = async () => {
  await store.dispatch('getToken', {email: email.value, password: password.value});
  await store.dispatch('getInvoices');
}

const logout = () => {
  store.commit('token', null);
}
</script>