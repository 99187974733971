import {DateTime} from "luxon";

export const formatDate = (date) => {
    return DateTime.fromISO(date).toFormat('d. MMMM yyyy', {locale: 'de-AT',})
}

const eurFormatter = new Intl.NumberFormat('de-AT', {
    style: 'currency',
    currency: 'EUR'
});

export const formatMoney = (amount) => {
    return eurFormatter.format(amount);
}