import {createStore} from "vuex";

import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
});

const store = createStore({
    state() {
        return {
            token: null,
            invoices: [],
        }
    },
    getters: {
        invoices: state => state.invoices,
        token: state => state.token,
    },
    mutations: {
        invoices: (state, invoices) => state.invoices = invoices,
        token: (state, token) => state.token = token,
    },
    actions: {
        async getToken(context, {email, password}) {
            try {
                const response = await api.post('/auth', {email: email, password: password});
                context.commit('token', response.data.token);
            } catch (error) {
                console.error(error);
            }
        },
        async getInvoices(context) {
            try {
                const response = await api.post('/active-invoices', {token: context.state.token});
                context.commit('invoices', response.data);
            } catch (error) {
                console.error(error);
            }
        },
        async createFile(context, {invoiceIds, collectionDate}) {
            await api.post('/elba-file', {
                invoiceIds,
                collectionDate,
                token: context.state.token
            });

           // await store.dispatch('getInvoices');
        },
    }
})

export default store
