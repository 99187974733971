<template>
  <div class="card my-5" v-if="token">
    <div class="card-header">
      <h4>Rechnungen</h4>
    </div>
    <div class="card-body">
      <p>Angezeigt werden Rechnungen im Zustand <span class="badge text-bg-warning bg-opacity-75">Entwurf</span> mit
        offenem Saldo von Mietern mit einem IBAN und dem Zahlungsmittel <em>Rechnung</em>, die mit dem Tag
        <span class="badge text-bg-success bg-opacity-75">ELBA</span> markiert sind.
      </p>
      <form @submit.prevent="createFile">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th>
              <input type="checkbox" @change="(event) => invoices.forEach(i => i.include = event.target.checked)"/>
            </th>
            <th>Datum</th>
            <th>Nummer</th>
            <th>Mieter</th>
            <th class="text-end">Saldo</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="invoice in invoices" :key="invoice.sid">
            <td>
              <input type="checkbox" v-model="invoice.include"/>
            </td>
            <td>{{ formatDate(invoice.date) }}</td>
            <td><a :href="`https://mein.mcself-mietlager.at/admin/invoices/${invoice.sid}`"
                   target="_blank">{{ invoice.sid.toUpperCase() }}</a></td>
            <td>
              {{ invoice.tenant }}
            </td>
            <td class="text-end">{{ formatMoney(invoice.balance) }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th>{{ invoices.filter(i => i.include).length }}</th>
            <th colspan="3"></th>
            <th class="text-end">
              {{ formatMoney(invoices.filter(i => i.include).map(i => i.balance).reduce((a, b) => a + b, 0)) }}
            </th>
          </tr>
          </tfoot>
        </table>
        <div class="mt-3">
          <div class="row gy-3">
            <div class="col-12">
              <label for="collection" class="col-form-label">Abbuchungsdatum</label><br>
              <input
                  type="date"
                  autocomplete="username"
                  v-model="collection"
                  class="form-control" id="collection"
                  :min="DateTime.now().toISODate()"
              >
            </div>
            <div class="col-12">
              <button
                  class="btn btn-sm btn-primary"
                  :disabled="!invoices.some(i => i.include) || processing"
                  @click="console.log(invoices.filter(i => i.include).map(i => i.sid.toUpperCase()).join(','))"
              >
                <i class="bi bi-envelope"></i> &nbsp;
                ELBA-Auftrag per E-Mail zustellen
              </button>
            </div>
          </div>
          <p class="mt-3">Der Auftrag wird Per E-Mail zugestellt. Beim Erzeugen des Auftrags werden die Rechnungen
            automatisch als bezahlt markiert.</p>
          <p>Wird eine Zahlung angefochten, markieren Sie die Rechnung in Storeganise als "Entwurf" und ergänzen Sie
            eine
            ausgehende Zahlung. Durch die Markierung als Entwurf wird die Rechnung wieder in der Liste hier
            sichtbar.</p>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import store from "@/store";
import {computed, ref} from "vue";
import {formatDate, formatMoney} from "@/util";

import {DateTime} from "luxon";

const token = computed(() => store.getters.token);
const processing = ref(false);

const collection = ref(DateTime.now().plus({days: 2}).toISODate());
const invoices = computed(() => store.getters.invoices);

const createFile = () => {
  processing.value = true;
  store.dispatch("createFile", {
    invoiceIds: invoices.value.filter(i => i.include).map(i => i.id),
    collectionDate: collection.value,
  });
  store.dispatch("getInvoices");
  processing.value = false;
}

</script>